<template>
  <div>
    <template v-if="tempCompetenceUnit">
      <b-table-simple>
        <b-thead>
          <b-th class="unit-competence-header">
            <div class="p-1 d-flex">
              <div style="white-space: nowrap">
                {{
                  $getVisibleNames(
                    "teaching.tempcompetenceunit",
                    false,
                    "Unidad de competencia"
                  ).toUpperCase()
                }}
                N°{{ tempCompetenceUnit.order }}:
              </div>
              <div class="w-100">
                <SentenceContainer :Sentence="tempCompetenceUnit">
                </SentenceContainer>
              </div>
              <div>
                <span
                  v-if="oldMatter && !oldMatter.automatic_achievement_weighing"
                  class="text-white secondary-color p-1 rounded"
                  style="white-space: nowrap"
                >
                  Ponderación: {{ tempCompetenceUnit.weighing | Round }}%
                </span>
                <button-edit
                  class="ml-2"
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  @click="openModalCompetenceUnit"
                  v-b-tooltip.v-secondary.noninteractive.top="
                    `Editar ${$getVisibleNames(
                      'teaching.tempcompetenceunit',
                      false,
                      'Unidad de competencia'
                    )}`
                  "
                ></button-edit>
                <b-modal
                  :id="`edit-unit-competence-modal-${tempCompetenceUnit.id}`"
                  hide-footer
                  :title="`Editar ${$getVisibleNames(
                    'teaching.tempcompetenceunit',
                    false,
                    'Unidad de competencia'
                  )}`"
                  size="lg"
                  no-enforce-focus
                  @hide="hideModalCompetenceUnit"
                >
                  <CompetenceUnitForm
                    v-if="oldMatter"
                    :matter_id="oldMatter.id"
                    :CompetenceUnit="tempCompetenceUnit"
                    :show_delete_button="true"
                    @created="
                      $bvModal.hide(
                        `edit-unit-competence-modal-${tempCompetenceUnit.id}`
                      )
                    "
                    @updated="
                      $bvModal.hide(
                        `edit-unit-competence-modal-${tempCompetenceUnit.id}`
                      )
                    "
                  ></CompetenceUnitForm>
                </b-modal>
              </div>
            </div>
          </b-th>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>
              <div class="d-flex flex-column">
                <draggable
                  v-if="oldMatter"
                  v-model="studyUnitsList"
                  :disabled="!allows_crud"
                  v-bind="dragOptions"
                  class="list-group"
                  group="study_unit"
                  handle=".handle"
                >
                  <transition-group type="transition" name="flip-list">
                    <template>
                      <UnitSection
                        v-for="study_unit in studyUnitsList"
                        :study_unit_id="study_unit.id"
                        :allows_crud="
                          allows_crud && oldMatter && !oldMatter.is_closed
                        "
                        :egress_profile_matter_id="egress_profile_matter_id"
                        :temp_competence_unit="temp_competence_unit_id"
                        :matter_id="oldMatter.id"
                        :key="`unit-section-${study_unit.id}`"
                        class="my-2"
                      ></UnitSection>
                    </template>
                  </transition-group>
                </draggable>
                <b-button
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  size="sm"
                  class="secondary-button ml-auto mr-auto mb-2"
                  @click="
                    $bvModal.show(
                      `add-study-unit-modal-${temp_competence_unit_id}`
                    )
                  "
                >
                  <b-icon-plus></b-icon-plus>Agregar
                  {{ $getVisibleNames("teaching.ramicro", true, "RA Micro") }}
                </b-button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </template>
    <template v-else>
      <div v-if="oldMatter">
        <draggable
          v-model="studyUnitsList"
          :disabled="!allows_crud"
          v-bind="dragOptions"
          class="list-group"
          group="study_unit"
          handle=".handle"
        >
          <transition-group type="transition" name="flip-list">
            <template>
              <UnitSection
                v-for="study_unit in studyUnitsList"
                :study_unit_id="study_unit.id"
                :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
                :egress_profile_matter_id="egress_profile_matter_id"
                :temp_competence_unit="temp_competence_unit_id"
                :matter_id="oldMatter.id"
                :key="`unit-section-${study_unit.id}`"
                class="my-2"
              ></UnitSection>
            </template>
          </transition-group>
        </draggable>
        <b-button
          v-if="allows_crud && oldMatter && !oldMatter.is_closed"
          size="sm"
          class="secondary-button my-2"
          @click="
            $bvModal.show(`add-study-unit-modal-${temp_competence_unit_id}`)
          "
          ><b-icon-plus></b-icon-plus>Agregar
          {{ $getVisibleNames("teaching.ramicro", true, "RA Micro") }}</b-button
        >
      </div>
    </template>
    <b-modal
      :id="`add-study-unit-modal-${temp_competence_unit_id}`"
      hide-footer
      :title="`Agregar ${$getVisibleNames(
        'teaching.ramicro',
        true,
        'RA Micro'
      )}`"
      size="lg"
    >
      <StudyUnitForm
        v-if="oldMatter"
        :matter_id="oldMatter.id"
        :egress_profile_matter_id="egress_profile_matter_id"
        :temp_competence_unit="temp_competence_unit_id"
        :show_temp_competence_unit="true"
        @created="
          $bvModal.hide(`add-study-unit-modal-${temp_competence_unit_id}`)
        "
        @close_modal="
          $bvModal.hide(`add-study-unit-modal-${temp_competence_unit_id}`)
        "
      ></StudyUnitForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "CompetenceUnits",
  components: {
    StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    UnitSection: () => import("./UnitSection"),
    CompetenceUnitForm: () => import("./CompetenceUnitForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    draggable,
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    egress_profile_matter_id: {
      type: [Number, null],
      default: null,
    },
    tempCompetenceUnit: {
      type: [Object, null],
      default: null,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      temp_competence_unit_id: this.tempCompetenceUnit
        ? this.tempCompetenceUnit.id
        : null,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyUnits: names.STUDY_UNITS,
      competence_units: names.COMPETENCE_UNITS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
    }),
    profileMatter() {
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    studyUnitsList: {
      get() {
        if (!this.oldMatter) return [];
        return this.studyUnits
          .filter(
            (x) =>
              x.matter == this.oldMatter.id &&
              x.temp_competence_unit == this.temp_competence_unit_id
          )
          .sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (
            !isNaN(element.id) &&
            (this.temp_competence_unit_id != null ||
              (element.temp_competence_unit == null &&
                this.temp_competence_unit_id == null))
          ) {
            let payload = {
              study_unit_id: element.id,
              item: {
                order: index + 1,
                temp_competence_unit: this.temp_competence_unit_id,
              },
            };
            if (
              element.temp_competence_unit != this.temp_competence_unit_id &&
              element.weighing > this.WeighingAvailable
            )
              payload.item.weighing = this.WeighingAvailable;
            this.$store
              .dispatch(names.PATCH_STUDY_UNIT, payload)
              .then((study_unit) => {
                if (
                  this.tempCompetenceUnit &&
                  this.tempCompetenceUnit.automatic_achievement_weighing &&
                  element.temp_competence_unit != this.tempCompetenceUnit.id &&
                  this.institution &&
                  this.institution.internal_use_id == "ciisa_uss"
                ) {
                  this.$restful
                    .Get(
                      `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${this.tempCompetenceUnit.id}`
                    )
                    .then(() => {
                      if (element.temp_competence_unit != null)
                        this.$restful
                          .Get(
                            `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${element.temp_competence_unit}`
                          )
                          .then(() => {
                            this.$store.dispatch(
                              names.FETCH_STUDY_UNITS,
                              study_unit.matter
                            );
                          });
                      else
                        this.$store.dispatch(
                          names.FETCH_STUDY_UNITS,
                          study_unit.matter
                        );
                    });
                }
              });
          }
        });
      },
    },
    WeighingAvailable() {
      let weighing = 100;
      this.studyUnitsList.forEach((element) => {
        weighing -= element.weighing;
      });
      return weighing;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "study_unit",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    openModalCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        if (this.profileMatter) {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            intelligence_option: 4,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [4],
            disabled_selected_intelligence_option: true,
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
          });
        }
      });
      this.$bvModal.show(
        `edit-unit-competence-modal-${this.tempCompetenceUnit.id}`
      );
    },
    hideModalCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        if (this.profileMatter) {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            competence_unit_id: null,
            intelligence_option: 2,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [2, 3, 4],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_study_unit: null,
            disabled_selected_competence_unit: null,
          });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table td:nth-child(3) {
  padding: 0;
}
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: black;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.unit-competence-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
</style>

